<template>
  <div class="mt-5">
    <div class="vx-col  w-full  m-5 mb-base">
      <vx-card class="mb-base">
        <div>
          <vs-row
            vs-align="flex-start"
            vs-type="flex"
            vs-justify="left"
            vs-w="12"
          >
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
              <div class="w-full mr-8">
                <label class="vs-input--label">Room</label>
                <v-select
                  :options="roomOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="roomFilter"
                  class="mb-4 md:mb-0"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3">
              <div class="w-full mr-8">
                <label class="block mb-2">Child</label>
                <v-select
                  :options="childrenOptions"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="childrenFilter"
                  class="mb-4 md:mb-0"
                />
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-divider cla ss="mb-0"></vs-divider>
        <div id="diagram"></div>
        <div class="flex justify-between items-center m-5">
          <vs-button class="mr-4 ml-auto" @click.stop="openPopup()"
            >Add Interaction</vs-button
          >
        </div>
      </vx-card>
    </div>
    <div class="diagram-toolTip" :style="position" v-show="showToolTip">
      <vs-card class="mb-0">
        <vs-row class="w-full">
          <vs-col class="flex w-full p-5">
            <div class="">
              <div
                class="img-container w-16 h-16 overflow-hidden rounded-full"
                style="height: 48px !important; width: 48px !important; overflow: hidden;"
              >
                <img
                  v-bind:src="tooltip.image"
                  alt="img"
                  class="responsive object-cover w-full h-full"
                />
              </div>
            </div>
            <div class="ml-4">
              <h4>{{ tooltip.name }}</h4>
              <p>{{ tooltip.interaction }} Interactions</p>
            </div>
          </vs-col>
        </vs-row>
      </vs-card>
    </div>

    <vs-popup
      class="holamundo add-children-popup"
      title
      :active.sync="popupActive"
    >
      <h2 class="h1 mb-6">Add Interaction for {{ selectedChild.fullName }}</h2>
      <form>
        <vx-card class="mx-0">
          <vs-row>
            <vs-col vs-w="12">
              <vx-input-group
                :class="'mb-base ' + searchText ? 'hasValue' : ''"
              >
                <vs-input
                  icon-no-border
                  icon="search"
                  label-placeholder="Search"
                  v-model="searchChild"
                  name="searchChild"
                />
              </vx-input-group>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12">
              <ul>
                <li>
                  <div class="round" style="display: flex; align-items: center">
                    <input
                      :id="'checkbox-a'"
                      type="checkbox"
                      ref="checkboxAllChildren"
                      @click="selectAllChildren(childrenToggle)"
                    />
                    <label :for="'checkbox-a'">
                      <i class="feather icon-check"></i>
                    </label>
                    <span
                      style="margin-left: 23px; font-size: 16px; line-height: 22px;"
                      >Select All</span
                    >
                  </div>
                </li>
                <li
                  v-for="(child, index) in searchChildrenInteractionList"
                  :key="index"
                >
                  <div class="round" style="display: flex; align-items: center">
                    <input
                      :id="'checkbox-' + index"
                      name="interactionChild"
                      type="checkbox"
                      v-model="interactionChildren"
                      :value="child"
                    />

                    <label :for="'checkbox-' + index">
                      <i class="feather icon-check"></i>
                    </label>
                    <vs-avatar
                      size="46px"
                      :src="child.photo"
                      style="margin-left: 22px;"
                    />
                    <span
                      style="margin-left: 6px; font-size: 16px; line-height: 22px;"
                      >{{ child.label }}</span
                    >
                  </div>
                </li>
                <span
                  class="text-danger text-sm"
                  v-show="errors.has('selectedChildren')"
                  >{{ errors.first("selectedChildren") }}</span
                >
              </ul>
            </vs-col>
          </vs-row>

          <span class="text-danger text-sm" v-show="errors.has('children')">{{
            errors.first("children")
          }}</span>
        </vx-card>
        <div class="flex justify-between mt-5">
          <vs-button class="mr-3 mb-2" @click="addChildInteractionData"
            >Save</vs-button
          >
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import * as d3 from "d3";
import _ from "lodash";

export default {
  components: {
    vSelect,
    d3
  },
  data() {
    return {
      mouseX: "",
      mouseY: "",
      position: {},
      showToolTip: false,
      tooltip: {
        name: "",
        image: "",
        interaction: ""
      },
      relationshipCircleDiagram: {},
      relationshipCircleList: {},
      relationshipCircleChildrenList: {},
      relationshipCircleChildrenList_bk: {},
      roomDetail: {},
      roomOptions: [],
      childrenOptions: [],
      childrenInteractionList: [],
      searchChildrenInteractionList: [],
      searchChild: "",
      selectedChild: {},
      interactionChildren: [],
      childrenToggle: false,
      selectedChildren: [],
      roomFilter: { label: "Select Room", value: "" },
      childrenFilter: { label: "Select Child", value: "" },
      svgProp: {
        width: 600,
        height: 600
      },
      popupActive: false
    };
  },

  computed: {
    teacher() {
      return this.$store.state.AppActiveUser;
    },
    positionCalc: function() {
      return {
        top: `${this.mouseY}px`,
        left: `${this.mouseX}px`
      };
    },

    svgChart() {
      const self = this;
      const myNode = document.getElementById("diagram");
      myNode.innerHTML = "";
      if (_.size(self.relationshipCircleChildrenList) <= 0) {
        myNode.innerHTML = "No Activities available";
        this.$vs.loading.close();
        return 0;
      }

      const radiusScale = d3
        .scaleSqrt()
        .domain([1, 300])
        .range([10, 80]);
      const bubble = d3
        .pack()
        .size([this.svgProp.width, this.svgProp.height])
        .padding(3);

      const svg = d3
        .select("#diagram")
        .append("svg")
        .attr("width", this.svgProp.width)
        .attr("height", this.svgProp.height)
        .attr("class", "bubble");

      const defs = svg.append("defs");

      const nodes = d3.hierarchy(this.processSvgData()).sum(function(d) {
        return d.interaction;
      });

      const node = svg
        .selectAll(".node")
        .data(bubble(nodes).descendants())
        .enter()
        .filter(function(d) {
          return !d.children;
        })
        .append("g")
        .attr("class", "node")
        .attr("transform", function(d) {
          return "translate(" + d.x + "," + d.y + ")";
        });

      defs
        .selectAll(".child-pattern")
        .data(bubble(nodes).descendants())
        .enter()
        .append("pattern")
        .attr("class", "child-pattern")
        .attr("id", d => {
          return d.data.className;
        })
        .attr("height", "100%")
        .attr("width", "100%")
        .attr("patternContentUnits", "objectBoundingBox")
        .attr("class", "child-pattern")
        .append("image")
        .attr("id", d => {
          return d.data.className;
        })
        .attr("height", "1")
        .attr("width", "1")
        .attr("preserveAspectRatio", "xMidYMin slice")
        .attr("viewBox", "0 0 60 55")
        .attr("xmlns:xlink", "https://www.w3.org/1999/xlink")
        .attr("xlink:href", d => {
          return d.data.image;
        })
        .attr("x", 0)
        .attr("y", 0);

      node.append("title").text(function(d) {
        return d.data.name + ": " + d.data.interaction;
      });
      node
        .append("circle")
        .attr("r", function(d) {
          return d.r;
        })
        .style("fill", function(d) {
          return "url(#" + d.data.className + ")";
        })
        .on("mouseover", (e, d) => {
          self.tooltip = {
            name: d.data.name,
            image: d.data.image,
            interaction: d.data.interaction
          };
          self.mouseX = e.pageX;
          self.mouseY = e.clientY;
          self.showToolTip = true;
        })
        .on("mouseout", () => {
          self.showToolTip = false;
        });

      d3.select(self.frameElement).style("height", this.svgProp.height + "px");

      this.$vs.loading.close();
      return 0;
    }
  },
  methods: {
    ...mapActions("centerAdmin", ["fetchCentreRoomList"]),
    ...mapActions("child", ["fetchChildrenByRoomId"]),
    ...mapActions("activity", ["getChildInteractionList"]),
    ...mapActions("interaction", [
      "saveTeacherInteraction",
      "getTeacherInteraction",
      "getTeacherInteractionFromOtherChild"
    ]),

    processSvgData() {
      let newDataSet = [];
      let self = this;
      _.forEach(this.relationshipCircleChildrenList, (child, k) => {
        newDataSet.push({
          name: child.fullName,
          className:
            child.fullName.toLowerCase().replace(/ /g, "") +
            "-" +
            ("undefined" != typeof child.interaction ? child.interaction : 0),
          interaction:
            "undefined" != typeof child.interaction ? child.interaction : 0,
          image:
            "undefined" != typeof child.photo &&
            "test.jop" != child.photo &&
            "" != child.photo
              ? child.photo
              : this.$defaultImage
        });
      });

      return { children: newDataSet };
    },

    async getChildrenByRoom(roomId) {
      let childrenList = await this.fetchChildrenByRoomId(roomId);
      childrenList = _.map(childrenList.data.data, o => {
        return {
          label: o.fullName,
          value: o._id,
          photo: o.photo
        };
      });
      if (_.size(childrenList) > 0) {
        childrenList = _.sortBy(childrenList, o => {
          return o.label;
        });
        this.childrenOptions = childrenList;
        this.childrenInteractionList = childrenList;
        this.searchChildrenInteractionList = childrenList;
        if (_.size(this.childrenOptions) > 0) {
          this.childrenFilter = {
            label: this.childrenOptions[0].label,
            value: this.childrenOptions[0].value
          };
        }
      } else {
        this.childrenFilter = { label: "", value: "" };
        this.childrenOptions = [];
      }
    },
    async getChildInteraction(selectedChildId) {
      let query = {
        roomId: this.roomFilter.value,
        childId: selectedChildId
      };
      let circleList = await this.getChildInteractionList(query);
      circleList = circleList.data.data;

      if (_.size(circleList) <= 0) {
        this.relationshipCircleChildrenList = {};
        await this.svgChart;
        return 0;
      }

      let selectedChildIdInteraction = 0;

      let getChildren = [];
      _.forEach(circleList, elem => {
        if ("undefined" != typeof elem.children) {
          _.forEach(elem.children, (child, k) => {
            if (_.size(getChildren) > 0) {
              if (
                "undefined" ==
                typeof _.find(getChildren, ["_id", child.childId])
              ) {
                child.childDetail.interaction = 0;
                getChildren.push(child.childDetail);
              }

              let index = _.findIndex(getChildren, ["_id", child.childId]);
              if (-1 != index) {
                if ("undefined" != typeof getChildren[index].interaction) {
                  getChildren[index].interaction =
                    parseInt(getChildren[index].interaction) + 1;
                } else {
                  getChildren[index].interaction = 1;
                }

                if(child.childId != selectedChildId){
                  selectedChildIdInteraction++;
                }
              }
            } else {
              child.childDetail.interaction = 0;
              getChildren.push(child.childDetail);
            }
          });
        }
      });

      getChildren = _.uniqBy(getChildren, "_id");

      let index = _.findIndex(getChildren, ["_id", selectedChildId]);
      if (-1 != index) {
        getChildren[index].selectedChild = true;
        this.selectedChild = getChildren[index];
      }

      this.relationshipCircleChildrenList = getChildren;

      let self = this;
      self.relationshipCircleChildrenList_bk =
        self.relationshipCircleChildrenList;

      let teacherInteraction = await this.getTeacherInteraction(
        selectedChildId
      );
      teacherInteraction = teacherInteraction.data.data.docs;

      if (teacherInteraction.length > 0) {
        _.forEach(teacherInteraction[0].interactionWith, elem => {
          let key = _.findIndex(self.relationshipCircleChildrenList, [
            "_id",
            elem.childId
          ]);

          if ("-1" == key) {
            let newElement = elem.childrenDetails;
            newElement.interaction = parseInt(elem.interactionPoint);
            self.relationshipCircleChildrenList.push(newElement);
          } else {
            self.relationshipCircleChildrenList[key].interaction =
              parseInt(self.relationshipCircleChildrenList[key].interaction) +
              parseInt(elem.interactionPoint);
          }

          selectedChildIdInteraction =
            selectedChildIdInteraction + parseInt(elem.interactionPoint);

          // if ("undefined" != typeof key) {
          //   if ("undefined" != typeof self.relationshipCircleChildrenList[key]) {
          //     self.relationshipCircleChildrenList[key].interaction = parseInt(self.relationshipCircleChildrenList[key].interaction) + parseInt(elem.interactionPoint * 3);
          //   }
          // }
        });
      }

      //interation of current child form other child
      let teacherInteractionFromOtherChild = await this.getTeacherInteractionFromOtherChild(
        selectedChildId
      );

      teacherInteractionFromOtherChild =
        teacherInteractionFromOtherChild.data.data.docs;

      if (teacherInteractionFromOtherChild.length > 0) {
        _.forEach(teacherInteractionFromOtherChild, elem => {
          let key = _.findIndex(self.relationshipCircleChildrenList, [
            "_id",
            elem.childId
          ]);

          if ("-1" == key) {
            let newElement = elem.childrenDetails;
            newElement.interaction = parseInt(
              elem.interactionWith.interactionPoint
            );
            self.relationshipCircleChildrenList.push(newElement);
          } else {
            self.relationshipCircleChildrenList[key].interaction =
              parseInt(self.relationshipCircleChildrenList[key].interaction) +
              parseInt(elem.interactionWith.interactionPoint);
          }

          selectedChildIdInteraction =
            selectedChildIdInteraction +
            parseInt(elem.interactionWith.interactionPoint);
        });
      }

      let selectedChildIndex = _.findIndex(
        self.relationshipCircleChildrenList,
        ["_id", selectedChildId]
      );
      self.relationshipCircleChildrenList[
        selectedChildIndex
      ].interaction = selectedChildIdInteraction;
      //console.log(self.relationshipCircleChildrenList,"sss")

      await this.svgChart;
    },

    async addChildInteractionData() {
      let self = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          let childIds = _.map(this.interactionChildren, "value");

          let data = new FormData();
          data.append("learningCenterId", self.teacher.learningCenterId);

          if (childIds.length > 0) {
            for (var i = 0; i < childIds.length; i++) {
              data.append("childrens[]", childIds[i]);
            }
          }

          data.append("selectedChild", this.selectedChild._id);
          this.saveTeacherInteraction(data)
            .then(async response => {
              this.$vs.loading();

              await this.$vs.notify({
                title: "Success",
                text: "Interaction added Successfully",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });

              await self.getChildInteraction(this.selectedChild._id);
              self.popupActive = false;
              self.$vs.loading.close();
            })
            .catch(err => {
              self.$vs.loading.close();
            });
          self.popupActive = false;
        }
      });
    },
    closePopup() {
      this.popupActive = false;
    },
    openPopup() {
      this.popupActive = true;
    },
    selectAllChildren(res) {
      this.childrenToggle = !res;
      if (this.childrenToggle) {
        this.interactionChildren = this.searchChildrenInteractionList;
      } else {
        this.interactionChildren = [];
      }
    }
  },
  watch: {
    roomFilter(obj) {
      this.roomDetail = obj;

      this.getChildrenByRoom(this.roomDetail.value);
    },
    childrenFilter(obj) {
      this.getChildInteraction(obj.value);
    },

    relationshipCircleChildrenList() {
      this.svgChart;
    },

    showToolTip(obj) {
      if (obj) {
        this.position = this.positionCalc;
      } else {
        this.tooltip = {
          name: "",
          image: "",
          interaction: 0
        };
        this.mouseX = "";
        this.mouseY = "";
      }
    },
    searchChild(obj) {
      if (obj) {
        this.searchChildrenInteractionList = _.filter(
          this.childrenInteractionList,
          elem => {
            return elem.label.toLowerCase().indexOf(obj) >= 0;
          }
        );
      } else {
        this.searchChildrenInteractionList = this.childrenInteractionList;
      }
    }
  },
  mounted() {},

  created() {
    this.$vs.loading();
    let self = this;
    this.centerId = this.teacher.learningCenterId;

    self.roomOptions = [
      {
        label: this.$store.state.AppActiveUser.room.name,
        value: this.$store.state.AppActiveUser.room._id
      }
    ];

    self.roomFilter = {
      label: this.$store.state.AppActiveUser.room.name,
      value: this.$store.state.AppActiveUser.room._id
    };

    // this.fetchCentreRoomList(this.centerId)
    //   .then(res => {
    //     self.roomOptions = _.map(res.data.data, o => {
    //       return {
    //         label: o.name,
    //         value: o._id
    //       };
    //     });
    //     self.roomOptions = _.sortBy(self.roomOptions, o => {
    //       return o.label;
    //     });

    //     self.roomFilter = {
    //       label: self.roomOptions[0].label,
    //       value: self.roomOptions[0].value
    //     };
    //   })
    //   .catch(err => {});
  }
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.deleteBtn {
  background-color: white;
  color: black;
  border: 2px solid #f44336;
}

.diagram-toolTip {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  pointer-events: none;
}

.diagram-toolTip > p {
  color: #000;
  text-align: left;
  padding: 1px;
  margin: 1px 10px 1px 10px;
}

#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.deleteBtn {
  background-color: white;
  color: black;
  border: 2px solid #f44336;
}

.diagram-toolTip {
  position: absolute;
  min-width: 250px;
  pointer-events: none;

  .vs-card--content {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
}

.diagram-toolTip > p {
  color: #000;
  text-align: left;
  padding: 1px;
  margin: 1px 10px 1px 10px;
}

.vs-popup {
  min-height: 300px;
}

.con-img-upload {
  padding-bottom: 50px;

  .img-upload {
    margin: 0 15px 15px 0;
  }
}

.con-input-upload {
  background: none;
  border: 2px solid #2d9ad5;
  border-radius: 5px;
  width: auto;
  height: auto;
  margin: 0;
  padding: 10px 20px;
  min-height: 42px;
  min-width: 150px;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 5px;
}

.con-input-upload {
  .vs-upload--button-upload {
    display: none;
  }
}

.con-input-upload {
  .text-input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    line-height: 19px;
    color: #2d9ad5;
    font-weight: 600;
    z-index: -1;
    min-width: auto;
  }
}

.add-children-popup,
.tag-children-popup {
  .vs-popup {
    @media (min-width: 992px) {
      width: 825px;
    }
  }

  .vs-popup--header {
    height: 0;
  }

  .vs-popup--close {
    transform: none !important;
    background: none !important;
    box-shadow: none;
    position: relative;
    top: 35px;
    right: 20px;
    font-size: 25px;
    color: #2b2d3d;

    &:hover {
      color: #2d9ad5;
    }
  }

  .vs-popup--content {
    padding: 45px 35px !important;
    margin: 0;
    width: 100%;

    .vx-card {
      box-shadow: none;

      .vx-card__body {
        // padding: 10px !important;
        border-radius: 8px !important;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);

        .vs-con-input-label.is-label-placeholder {
          margin-top: 0 !important;
          margin-bottom: 25px;
        }
      }
    }
  }

  .vs-checkbox-primary {
    .vs-checkbox {
      width: 20px !important;
      height: 20px !important;
      border: 1px solid #289ad5 !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      overflow: hidden;
      transform: none;
    }
  }

  .round {
    label {
      cursor: pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #289ad5 !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      transform: none;
      margin: 0 5px;
      background: transparent;
      transition: 0.4s all ease-in-out;

      i {
        opacity: 0;
      }
    }
  }
}

.add-children-popup {
  .vs-checkbox-primary {
    .vs-checkbox {
      margin-right: 5px;
      border: none !important;

      .vs-checkbox--check {
        border: 1px solid #2d9ad5 !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: none;
        transition: 0.4s all ease-in-out;
      }

      .vs-icon {
        opacity: 0;
      }
    }
  }
}

.add-children-popup
  .vs-checkbox--input:checked
  + .vs-checkbox
  .vs-checkbox--check {
  transform: none;
  top: 0;
  left: 0;
  border: 10px solid #2d9ad5 !important;
  width: 100%;
  height: 100%;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}

.add-children-popup .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  transform: none;
  opacity: 1;
}

.add-children-popup ul li {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.add-children-popup .vs-checkbox-primary .con-slot-label {
  margin-left: 6px;
  font-size: 16px;
  line-height: 22px;
}

.round {
  position: relative;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  border: 10px solid #2898d5 !important;
  animation: shrink-bounce 200ms cubic-bezier(0.4, 0, 0.23, 1);
}

.round input[type="checkbox"]:checked + label i {
  opacity: 1;
  position: relative;
  top: 1px;
  left: -10px;
  font-size: 20px;
  color: #ffffff;
  transform: none;
}

@keyframes shrink-bounce {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}

@mixin rangeThumb {
  width: 18px;
  height: 18px;
  margin: -8px 0 0;
  border-radius: 50%;
  background: #37adbf;
  cursor: pointer;
  border: 0 !important;
  position: relative;
  z-index: 1;
}

@mixin rangeTrack {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: grey;
}

.range {
  position: relative;
  width: 900px;
  height: 5px;
}

.range input[type="radio"] {
  border: 13.5px solid #2898d5;
  border-radius: 50%;
  width: 27px;
  position: absolute;
  height: 27px;
  -webkit-appearance: none;
  top: -12px;
  right: -12px;
  transition: 0.4s all ease-in-out;
  z-index: 1;
  opacity: 0;

  &:checked {
    opacity: 1;
  }
}

// Labels below slider
.range-labels {
  background: #f0f0f0;
  height: 6px;
  border-radius: 3px;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    position: relative;
    width: calc(100% / 5);
    text-align: left;
    color: black;
    font-size: 14px;
    height: 6px;
    top: -9px;
    cursor: pointer;

    &:first-child {
      width: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: -4px;
      right: 0;
      width: 3px;
      height: 13px;
      background: #606060;
    }

    label {
      position: absolute;
      top: 20px;
      right: -2px;
    }
  }

  .active {
    color: #37adbf;
  }

  .selected::before {
    background: #37adbf;
  }

  .selected::after {
    background: #37adbf;
  }

  .active.selected::before {
    display: none;
  }
}

.learning-activity-table {
  tr {
    th {
      padding: 10px 30px;

      &:nth-child(1) {
        width: 305px;
      }

      &:nth-child(2) {
        width: 780px;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 180px;
      }

      &:nth-child(5) {
        width: 250px;
      }

      @media (max-width: 480px) {
        padding: 10px 15px;
      }
    }

    td {
      padding: 10px 30px;
      @media (max-width: 480px) {
        // padding: 15px;
      }
    }
  }
}

.con-vs-slider {
  z-index: 1;
}

.vs-slider--circle {
  background: #289ad5;
}

.range-labels li::before {
  z-index: 1;
}

.textarea1 {
  @media (max-width: 600px) {
    .vs-xs-12 {
      margin-bottom: 0 !important;
    }
  }
}

.textarea2 {
  @media (max-width: 600px) {
    .vs-xs-12 {
      padding: 0 !important;
    }
  }
}

.photo-wrapper {
  .vs-button {
    display: inline-block;
    margin-top: 20px;
  }
}

.vue-lb-figure {
  padding-bottom: 20px;
  line-height: 0;
}

.vue-lb-footer {
  padding: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.vue-lb-info {
  background: none;
  position: absolute;
  left: 0;
  bottom: 8px;
  text-align: left;
  height: auto;
  z-index: 1;

  a {
    font-weight: 700;
    text-decoration: underline;
    color: #50c1ff;
  }
}

.follow-activity-table {
  .text-large {
    font-size: 20px;
    margin-right: 10px;
  }

  tr {
    td {
      vertical-align: top;
      padding: 10px 30px;

      &:first-child {
        padding: 10px 30px;
      }
    }
  }
}

.tag-children-popup {
  .vs-popup--title {
    visibility: hidden;
  }

  .vs-popup {
    min-height: 500px;

    p {
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 5px;
    }

    .con-select {
      .vs-select--input {
        padding: 20px;
        border: 1px solid #838383;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

.vs-select-primary .vs-select--item {
  font-size: 16px;
  padding: 10px;

  span {
    font-size: 16px;
    line-height: 25px;
  }

  .vs-select--item-icon {
    font-size: 30px;
  }

  &.activex {
    padding-left: 40px;
  }
}

.vs-collapse {
  .vs-component {
    align-items: flex-start;
  }
}

.vs-collapse-item {
  border: none;
  margin-bottom: 30px;
  padding-right: 30px;

  .vs-collapse-item--header {
    padding: 0;

    .con-vs-checkbox {
      align-items: flex-start;
      min-width: 60px;
    }

    .icon-header {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #2d99d3;
      color: #fff;
      right: -60px;
      top: 15px;

      .vs-icon {
        font-size: 0;
        position: relative;
        width: 100%;
        height: 100%;

        &:before {
          content: "\e8b1";
          font-family: feather;
          font-size: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .vs-collapse-item--content {
    .con-content--item {
      padding-left: 0 !important;
    }
  }

  &.open-item {
    .vs-collapse-item--header {
      .icon-header {
        .vs-icon {
          &:before {
            content: "\e897";
          }
        }
      }
    }
  }
}

.sticky {
  margin: -30px -30px 0;
  padding: 30px 30px 0;
  top: 0;
  z-index: 10;
  background: #ffffff;
}

.vuesax-app-is-ltr .vs-input--icon {
  font-size: 2rem;
  top: auto;
  left: 10px;
}
</style>
